<template>
  <div class="dashboard-content" data-app>
    <div class="row">
      <div class="col-12 text-center">
        <h3>Ficha de {{ company.name }}</h3>
        <span v-if="company.created_at"
          >Fecha de creación:
          {{
            createdAt.toLocaleDateString('es-VE', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })
          }}. {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
        >
      </div>
    </div>
    <!-- Content -->
    <div
      class="card"
      v-for="group in arrayOfData"
      :key="group.id"
      style="padding: 2rem; margin: 20px 0px"
    >
      <h5 style="margin-bottom: 30px">{{ group.title }}</h5>
      <div class="row">
        <v-form ref="formCompanyData" v-model="valid" style="width: 100%" class="row">
          <v-col
            cols="12"
            lg="4"
            md="6"
            sm="12"
            v-for="singleData in group.data"
            :key="singleData.id"
          >
            <!--<div v-if="singleData.selection != 1">-->
            <div class="row">
              <label for="" style="margin: 0px auto 15px auto">{{
                singleData.description
              }}</label>
            </div>
            <div id="removePasteFormat">
              <vue-editor
                :editorToolbar="customToolbar"
                :editorOptions="editorSettings"
                v-model="singleData.values[0]"
              />
            </div>
            <!--</div>-->

            <!--<v-textarea
							v-if="singleData.selection != 1"
							clearable
							counter
							clear-icon="mdi-close-circle"
							required
							:rules="[rules.max]"
							:label="singleData.description"
							v-model="singleData.values"
						></v-textarea>-->

            <!--<div v-else>
							<div class="row">
								<label for="" style="margin: 0px auto 15px auto">{{
									singleData.description
								}}</label>
							</div>

							<div v-for="(input, index) in inputsCount" :key="index">
								<v-text-field
									style="padding: 0px; margin 0px"
									v-model="singleData.values[index]"
								></v-text-field>
							</div>
						</div>-->
          </v-col>
        </v-form>
      </div>
    </div>
    <v-col cols="12" style="margin-bottom: 20px; height: 10%">
      <div class="row">
        <v-btn
          style="margin: auto; margin-top: 10px; color: #fff"
          v-if="flow == 'create'"
          :disabled="!valid"
          dark
          color="#003a56"
          @click="createItem()"
        >
          Crear
        </v-btn>
        <v-btn
          style="margin: auto; margin-top: 10px; color: #fff"
          :disabled="!valid"
          dark
          color="#003a56"
          @click="saveItem()"
        >
          Guardar
        </v-btn>

        <!--v-btn
						style="margin: auto; margin-top: 10px; color: #fff"
						color="#003a56"
						dark
						@click="reset"
					>
						Limpiar
					</v-btn>-->
        <v-btn
          style="margin: auto; margin-top: 10px; color: #fff"
          color="#003a56"
          dark
          @click="$router.go(-1)"
        >
          Cancelar
        </v-btn>
      </div>
    </v-col>
  </div>
</template>

<script>
  import { VueEditor } from 'vue2-editor'
  import SimpleList from 'Components/Listing/SimpleList.vue'

  export default {
    name: 'CompanyData',
    components: {
      SimpleList,
      VueEditor,
    },
    data() {
      return {
        loading: false,
        headers: [
          { text: 'N°', value: 'id' },
          { text: 'Tipo', value: 'typeText' },
          { text: 'Dato', value: 'description' },
          { text: 'Valor', value: 'company_data.value' },
          { text: 'Acciones', value: 'actions', align: 'center' },
        ],
        typeDatas: [
          { code: 10, name: 'ACTIVIDADES' },
          { code: 14, name: 'CONTRATACIONES' },
          { code: 2, name: 'DATOS DE LA EMPRESA FRANQUICIANTE' },
          { code: 3, name: 'DATOS OPERATIVOS' },
          { code: 11, name: 'EXPERIENCIA' },
          { code: 4, name: 'INFORMACIÓN ECONÓMICA' },
          { code: 13, name: 'INFORMACION GENERAL' },
          { code: 9, name: 'INFORMACIÓN SOBRE UBICACIÓN Y LOCAL REQUERIDO' },
          { code: 1, name: 'OBJETIVO' },
          { code: 5, name: 'OTROS DATOS IMPORTANTES' },
          { code: 15, name: 'PRODUCTOS O SERVICIOS QUE OFRECEN' },
          { code: 6, name: 'RESEÑA' },
          { code: 12, name: 'SERVICIOS ESPECIALIZADOS' },
          { code: 8, name: 'TIPO DE FRANQUICIAS QUE OFRECEN' },
          { code: 7, name: 'UBICACIÓN Y DATOS DE CONTACTO' },
        ],
        inputsCount: 2,
        flow: '',
        companyType: 0,
        active: false,
        address: '',
        cityId: '',
        email: '',
        keyword: '',
        latitude: '',
        longitude: '',
        link: '',
        phone: '',
        name: '',
        userId: '',
        description: '',
        outstanding: false,
        company: [],
        dataAssigned: [],
        cities: [],
        countries: [],
        countryId: '',
        selectCountryData: [],
        selectCityData: [],
        datas: [],
        createdAt: '',
        toDay: new Date(),
        dateRange: '',
        arrayOfData: [],
        valid: true,
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => v.length >= 8 || '8 caracteres como mínimo',
          max: (v) => v.length <= 200 || 'Debe poser menos de 200 caracteres',
          maxNumber: (v) => v.length <= 18 || 'Debe poser menos de 18 caracteres',
          url: (v) => this.isURL(v) || 'La URL es inválida',
        },
        customToolbar: [
          //[{ header: [true, '1', '2', '3', '4', '5', '6'] }],
          [{ align: 'center' }, { align: 'justify' }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          //[{ indent: '-1' }, { indent: '+1' }],
          ['bold', 'italic', 'underline'],
          ['blockquote', 'link'],
        ],
        editorSettings: {
          formats: ['bold', 'italic', 'link', 'list', 'blockquote'],
        },
      }
    },
    created() {
      this.getCompany()
    },
    mounted() {
      //this.onPaste()
    },
    methods: {
      onPaste() {
        const x = document.getElementById('removePasteFormat')
        x.addEventListener('paste', (e) => {
          e.stopPropagation()
          e.preventDefault()
          let text = e.clipboardData.getData('text/plain')
          // access the clipboard using the api
          if (document.queryCommandSupported('insertText')) {
            document.execCommand('insertText', false, text)
          } else {
            document.execCommand('paste', false, text)
          }
        })
      },
      async getCompany() {
        this.loading = true
        axios
          .get(process.env.VUE_APP_API_DIRECTORY + 'companies/' + this.$route.query.id)
          .then((response) => {
            this.company = response.data
            this.companyType = this.company.type
            this.dataAssigned = this.company.data
            this.getData()
            this.loading = false
            this.createdAt = new Date(this.company.created_at)
            this.dateRange = Math.ceil(
              Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
            )
          })
      },

      async getData() {
        this.loading = true
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'data?groupByType=1&limit=100&company_type=' +
              this.companyType
          )
          .then((response) => {
            this.datas = response.data
            this.createArrayOfData()
            this.setValuesToArrayOfData()
            this.loading = false
          })
      },

      createArrayOfData() {
        this.typeDatas.forEach((typeData) => {
          if (
            this.datas.filter((backData) => backData.type == typeData.code).length > 0
          ) {
            this.arrayOfData.push({
              title: typeData.name,
              data: this.datas.filter((backData) => backData.type == typeData.code),
            })
          }
        })
      },

      setValuesToArrayOfData() {
        let values = []
        this.arrayOfData.forEach((level1) => {
          level1.data.forEach((level2) => {
            values = []
            level2.values = []
            values = this.company.data.filter((data) => data.id == level2.id)
            if (level2.selection == 0) {
              if (values.length == 0) {
                level2.values.push('')
              } else {
                level2.values.push(values[0].company_data.value)
              }
            } else {
              if (values.length > 1) {
                values.forEach((itemValue) => {
                  if (
                    level2.values.filter((item) => item == itemValue.company_data.value)
                      .length == 0
                  ) {
                    level2.values.push(itemValue.company_data.value)
                  }
                })
              } else if (values.length == 1) {
                level2.values.push(values[0].company_data.value)
              } else if (values.length == 0) {
                level2.values.push('')
              }
            }
          })
        })
        this.onPaste()
      },

      async saveItem() {
        this.loading = true
        let data = []
        this.active = this.active ? 1 : 0
        this.outstanding = this.outstanding ? 1 : 0

        this.arrayOfData.forEach((level1) => {
          level1.data.forEach((level2) => {
            if (Array.isArray(level2.values)) {
              let counter = 1
              level2.values.forEach((singleValue) => {
                data.push({ data_id: level2.id, value: singleValue, count: counter++ })
              })
            } else {
              data.push({ data_id: level2.id, value: level2.values, count: 1 })
            }
          })
        })
        axios
          .patch(process.env.VUE_APP_API_DIRECTORY + 'companies/' + this.company.id, {
            data: data,
          })
          .then((response) => {
            this.getCompany()
            this.arrayOfData = []
          })
          .catch((error) => {})
          .finally(() => (this.loading = false))
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getCompany()
      },
    },
  }
</script>
<style lang="scss">
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    padding: 0 10px;
    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  }
  /*.v-text-field__details {
		display: block;
		flex: 1 0 auto;
		max-width: 100%;
		min-height: 14px;
		overflow: hidden;
	}*/
  .col-lg-4,
  .col-md-6,
  .col-sm-12 {
    padding: 0px auto !important;
  }
  .theme--light.v-input {
    color: rgba(0, 0, 0, 0.87);
    padding: 0px;
    margin: 0px;
  }
  .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
    display: flex;
    flex: 1 1 auto;
    position: relative;
    width: 100%;
  }
  .v-text-field .v-label--active {
    transform: translateY(-20px) translateX(-10%) scale(0.75);
  }
  .ql-snow .ql-toolbar button,
  .ql-snow.ql-toolbar button {
    width: 18px !important;
  }
  .ql-snow .ql-toolbar button svg,
  .quillWrapper .ql-snow.ql-toolbar button svg {
    width: 18px !important;
    height: 18px !important;
  }
  .quillWrapper .ql-snow.ql-toolbar .ql-formats {
    margin-bottom: 0px !important;
  }
</style>
